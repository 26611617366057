<template>
  <a-card :bordered="false" class="card-area userDetail">
    <!-- 用户信息 -->
    <a-card title="用户信息">
      <a-descriptions title="" bordered size="small" :column="{sm:2}">
        <a-descriptions-item label="昵称">{{user.nickName}}</a-descriptions-item>
        <a-descriptions-item label="用户ID">{{user.identityId}}</a-descriptions-item>
        <a-descriptions-item label="手机号">{{user.phone ? user.phone : "暂未绑定手机号"}}</a-descriptions-item>
        <a-descriptions-item label="邮箱">{{user.email ? user.email : "暂未绑定邮箱"}}</a-descriptions-item>
        <a-descriptions-item label="上次登录时间">{{this.dateFormat(user.lastLoginTime)}}</a-descriptions-item>
      </a-descriptions>


    </a-card>

    <!-- 客户信息 -->
    <a-card class="device-info" title="客户信息">
      <!-- 表格区域 -->
      <common-table
              ref="userTable"
              path="subUser"
              :query-params="userQueryParams"
              :columns="userColumns"
      >

        <template slot="operation" slot-scope="{record}">
          <action-delete  @click="removeUser(record)"></action-delete>
        </template>

      </common-table>
    </a-card>

  </a-card>
</template>
<script>
  export default {
    data () {
      return {
        user: {
          nickName:"",
          identityId:"",
          phone:"",
          email:"",
          lastLoginTime: ''
        },
        userQueryParams:{
          identityId:this.$route.params.identityId
        }

      }
    },
    created () {
      this.$get(`appUser/${this.$route.params.identityId}`, {}).then(r => {
        this.user = r.data
      })

    },
    computed: {
      deviceInfoColumns () {
        return [
          {
            title: '产品',
            dataIndex: 'nickname'
          },
          {
            title: '设备ID',
            dataIndex: 'iotId'
          },
          {
            title: '押金',
            dataIndex: 'cashPledge'
          },
          {
            title: '添加时间',
            dataIndex: 'createdAt',
          },
          {
            title: '操作',
            dataIndex: 'operation',
            width:120,
            scopedSlots: { customRender: 'operation' }
          }
        ]
      },
      userColumns(){
        return [
          {
            title: '昵称',
            dataIndex: 'nickName'
          },
          {
            title: '手机号',
            dataIndex: 'phone'
          },
          {
            title: '添加时间',
            dataIndex: 'createdAt',
          },
          {
            title: '操作',
            dataIndex: 'operation',
            width:120,
            scopedSlots: { customRender: 'operation' }
          }
        ]
      }
    },
    methods: {
      dateFormat (time) {
        var now = new Date(time)
        var year = now.getFullYear()
        var month = this.fullFormat(now.getMonth() + 1)
        var day = this.fullFormat(now.getDate())
        var hour = this.fullFormat(now.getHours())
        var minute = this.fullFormat(now.getMinutes())
        var seconds = this.fullFormat(now.getSeconds())
        return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + seconds
      },
      fullFormat (time) {
        var string = time.toString()
        if (string.length === 1) {
          string = '0' + string
        }
        return string
      },

      add(){
        this.$refs.userDeviceEdit.show({identityId:this.$route.params.identityId})
      },
      edit(record){
        this.$refs.userDeviceEdit.show(record)
      },
      remove(record){
        var that = this
        this.$confirm({
          title: '是否删除?',
          content: '该操作不可撤销',
          centered: true,
          onOk () {
            that.$delete('userDevice/' + record.id).then(() => {
              that.$message.success('删除成功')
              that.getList()
            })
          }
        })
      },
      removeUser(record){
        var that = this
        this.$confirm({
          title: '是否删除?',
          content: '该操作不可撤销',
          centered: true,
          onOk () {
            that.$delete('subUser/' + record.id).then(() => {
              that.$message.success('删除成功')
              that.$refs.userTable.getData()
            })
          }
        })
      },
      getList(){
        this.$refs.deviceTable.getData()
      }
    }
  }
</script>
<style lang="less" scoped>
  .user-info {
    width: 560px;
    &-content {
      display: flex;
      p {
        font-size: 12px;
        color: #999;
        margin-bottom: 10px;
      }
      &-left {
        margin-left: 16px;
      }
      &-right {
        margin-left: 26px;
      }
    }
  }
</style>
<style lang="less">
  // 修改ant样式
  .userDetail {
    .device-info {
      margin: 24px 0;
      .ant-card-head {
        border-bottom: none;
      }
      .ant-card-body {
        padding-top: 0;
      }
    }
    .device-info-table {
      .ant-table-thead > tr > th {
        color: #999;
        padding: 6px 16px;
      }

    }
  }
</style>
